import React, {Component} from 'react';
import App from 'base-shell/lib';
import config from './config';

//CSS File Here
import "aos/dist/aos.css";
import "./assets/css/plugins/animate.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/swiper.min.css";
import "./assets/css/style.css";
export default class Quads extends Component {
    render() {
        return (<App config={config}/>)
    }
}
